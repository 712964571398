export const AUTH_ROUTES = [
  "/register",
  "/login",
  "/reset-password",
  "/verify-account",
];

export const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const KGEC_NAMES: Array<String> = [
  "kalyani government engineering college",
  "Kalyani Government Engineering college, kalyani",
  "kgec",
  "kalyani govt. engineering college",
  "kalyani govt. engg. college",
];
