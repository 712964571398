import React from "react";

import DSCLogo from "../../assets/dsc-logo.png";

import "./Footer.scss";

const Footer: React.FC = () => {
  return (
    <div className="footer-container container">
      <div className="footer-wrapper wrapper">
        <div className="copyright">
          <div className="p1">
            Designed and Developed by <img src={DSCLogo} alt="" />{" "}
            <a href="https://www.gdsckgec.in/" target="_blank" rel="noreferrer">
              GDSC-KGEC
            </a>
          </div>
          <p className="p2">Copyright © 2024 GDSC-KGEC</p>
        </div>
        <div className="text-16">
          For any queries, please contact{" "}
          <a href="mailto:dsckgec@gmail.com">dsckgec@gmail.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
