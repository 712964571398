import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { BASE_API_URL } from "../utils/constants";

const configForImageUpload = {
  headers: {
    "UTILS-API-KEY": process.env.REACT_APP_UTILS_API_KEY,
    "content-type": "multipart/form-data",
  },
};

export default abstract class API {
  private static instance: AxiosInstance;

  // Create a new axios instance
  static create() {
    this.instance = axios.create({
      baseURL: BASE_API_URL,
    });

    // Add a request interceptor with config for refreshing token
    this.instance.interceptors.request.use(
      async (config) => {
        if (!config.headers.Authorization) {
          const token = localStorage.getItem("token");
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }
        return config;
      },
      async (error) => await Promise.reject(error)
    );
  }

  static resetToken = () => {
    let token = localStorage.getItem("token");

    if (token) {
      this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete this.instance.defaults.headers.common.Authorization;
    }
  };

  // GET, POST, PUT, DELETE, PATCH
  static get = <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => this.instance.get<T>(url, config);

  static post = <T = any, D = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<D>
  ) => this.instance.post<T>(url, data, config);

  static delete = <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => this.instance.delete<T>(url, config);

  static put = <T = any, D = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<D>
  ) => this.instance.put<T>(url, data, config);

  static patch = <T = any, D = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<D>
  ) => this.instance.patch<T>(url, data, config);

  static updateToken = (token: string | null) => {
    if (token) {
      this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete this.instance.defaults.headers.common.Authorization;
    }
  };

  // USER AUTH
  static signUpUser(signUpData: UserSignUp) {
    return API.post("/api/users/signup", signUpData);
  }
  static signInUser(signInData: UserSignIn) {
    return API.post("/api/users/login", signInData);
  }
  static sendUserOTP(config: any) {
    return API.post("/api/users/sendotp", null, config);
  }
  static verifyUserOTP(otpData: OTPObj, config: any) {
    return API.post("/api/users/verifyotpforuser", otpData, config);
  }
  static sendOTPForResetPassword(emailDetails: { email: string }) {
    return API.post("/api/users/sendotpreset", emailDetails);
  }
  static verifyOTPForResetPassword(otpData: OTPObjForResetPassword) {
    return API.post("/api/users/verifyotpforreset", otpData);
  }

  // INVITE USER
  static inviteUser(emailDetails: { email: string }) {
    return API.post("/api/users/inviteuser", emailDetails);
  }

  // UPLOAD IMAGES
  static uploadImage(imageData: FormData) {
    return API.put("/api/utils/uploadimages", imageData, configForImageUpload);
  }

  // EVENTS
  static getAllEvents() {
    return API.get("/api/events/all?dpp=20000");
  }
  static getAllEventsForClub(clubName: string) {
    return API.get(`/api/events/all?eventOrganiserClub.name=${clubName}`);
  }
  static getEventDetails(eventId: string) {
    return API.get(`/api/events/${eventId}`);
  }
  static registerForEvent(eventRegisterData: any) {
    return API.post("/api/events/register", eventRegisterData);
  }

  // USER PROFILE
  static getUserDetails() {
    return API.get("/api/users/profile");
  }
  static getUserDetailsWithConfig(config: any) {
    return API.get("/api/users/profile", config);
  }
  static verifyUser(espektroId: string) {
    return API.get(`/api/users/verifyespektroid/${espektroId}`);
  }

  // TICKETS
  static getAllTickets() {
    return API.get("/api/tickets/allforusers");
  }
  static getTicketDetails(ticketId: string) {
    return API.get(`/api/tickets/${ticketId}`);
  }

  // TRANSACTION
  static createTransaction(amountData: { amount: string }) {
    return API.post("/api/users/transaction", amountData);
  }
  static updateTransaction(transactionData: {
    transactionId: string;
    paymentId: string;
  }) {
    return API.put("/api/users/transaction", transactionData);
  }
  static refreshTransaction(transactionIdDetails: { transactionId: string }) {
    return API.patch("/api/users/transaction", transactionIdDetails);
  }
}

API.create();
