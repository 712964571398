/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import QRImg from "../../assets/qr.png";
import UserInfoImg from "../../assets/user-info.png";
import WalletImg from "../../assets/wallet.png";
import TicketImg from "../../assets/ticket.png";
import LogoutImg from "../../assets/logout.png";
import EspektroLogo from "../../assets/espektro-small-logo.svg";

import RefreshUser from "../RefreshUser/RefreshUser";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import Coin from "../Coin/Coin";

import API from "../../api/api";
import { showAppMessage } from "../../utils/baseUtils";

import "./Navbar.scss";

const Navbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  // const user = JSON.parse(localStorage.getItem("user") || "");

  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState<any>({});
  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false);
  const [fullScreenLoading, setFullScreenLoading] = useState<boolean>(false);

  const logout = () => {
    const yes = window.confirm("Do you want to log out?");
    if (yes) {
      localStorage.clear();
      API.updateToken(token);
      navigate("/login");
    }
  };

  const loadUserProfile = async (config: any) => {
    try {
      const { data } = await API.getUserDetailsWithConfig(config);
      // console.log(data.user);
      // localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(data.user));
      setUser(data.user);
      setTimeout(() => {
        setFullScreenLoading(false);
      }, 2000);
    } catch (error: any) {
      showAppMessage(error, "error");
    }
  };

  useEffect(() => {
    setAuth(token !== null ? true : false);

    if (token) {
      setFullScreenLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      loadUserProfile(config);

      // const userData = getUserFromlocalStorage();
      // // console.log(userData);
      // if (userData) {
      //   setUser(userData);
      // } else {
      //   window.location.reload();
      // }
    }
  }, [token]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleOutsideClick = (event: MouseEvent) => {
        if (isSidebarOpened && event.target instanceof Node) {
          const targetElement = event.target as Element;
          if (
            !targetElement.closest(".nav-sidebar") &&
            !targetElement.closest(".menu-toggle")
          ) {
            setIsSidebarOpened(false);
          }
        }
      };

      if (isSidebarOpened) {
        window.addEventListener("click", handleOutsideClick);
      }

      return () => {
        window.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [isSidebarOpened]);

  const menuItems: MenuItem[] = [
    {
      label: "Home",
      icon: <i className="fa-solid fa-house"></i>,
      path: "/",
      isActive: location.pathname === "/",
    },
    {
      label: "My QR Code",
      icon: QRImg,
      path: "/my-qr-code",
      isActive: location.pathname === "/my-qr-code",
    },
    {
      label: "Personal Information",
      icon: UserInfoImg,
      path: "/my-info",
      isActive: location.pathname === "/my-info",
    },
    {
      label: "My Wallet",
      icon: WalletImg,
      path: "/my-wallet",
      isActive: location.pathname === "/my-wallet",
    },
    {
      label: "My Tickets",
      icon: TicketImg,
      path: "/my-tickets",
      isActive: location.pathname === "/my-tickets",
    },
    { label: "Logout", icon: LogoutImg }, // Assuming logout has a separate handler
  ];

  return (
    <header className="navbar-section sticky">
      <div className="navbar-container container">
        {/* <div className="page-wrapper wrapper"> */}
        <div className="nav-wrapper wrapper">
          {fullScreenLoading ? (
            <FullScreenLoader />
          ) : (
            <nav className="navbar">
              <div className="logo" onClick={() => navigate("/")}>
                <img src={EspektroLogo} alt="Company Logo" />
                {/* <h1>IT</h1> */}
              </div>
              {token !== null && user ? (
                <div className="nav-actions">
                  <div className="user-profile">
                    <img
                      src={user?.profileImageUrl}
                      className="user-img"
                      alt=""
                    />
                    <div className="details">
                      <h4>{user?.name}</h4>
                      <div className="coin-details">
                        <span>{user?.coins}</span>
                        <Coin />
                        <span>coins</span>
                      </div>
                    </div>
                  </div>
                  <div
                    // className={`menu-toggle ${isSidebarOpened ? "is-active" : ""}`}
                    className="menu-toggle"
                    id="mobile-menu"
                    onClick={() => setIsSidebarOpened(true)}
                  >
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                  </div>
                  <div
                    className={`menu-toggle ${
                      isSidebarOpened ? "is-active" : ""
                    }`}
                    id="desktop-menu"
                    onClick={() => setIsSidebarOpened(!isSidebarOpened)}
                  >
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                  </div>
                </div>
              ) : (
                <div className="nav-actions">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </button>
                </div>
              )}
              <div
                className={`nav-sidebar mobile-sidebar ${
                  isSidebarOpened ? "nav-sidebar-opened" : ""
                }`}
              >
                <div className="user-profile-box">
                  <div className="top-title-bar">
                    <h4 className="text-18">My Profile</h4>
                    {/* <div className="img-icon">
                    <img src={BlueQRImg} alt="" />
                  </div> */}
                    <i
                      className="fa-solid fa-xmark"
                      onClick={() => setIsSidebarOpened(false)}
                    ></i>
                  </div>
                  <div className="user-details">
                    <img src={user?.profileImageUrl} alt="" />
                    <div className="text-details">
                      <p className="user-name">
                        {user?.name} <RefreshUser setUser={setUser} />
                      </p>
                      <p>
                        <span className="text-purple">{user?.espektroId}</span>{" "}
                        (Espektro ID)
                      </p>
                    </div>
                  </div>
                </div>
                <ul
                  className={`nav ${
                    isSidebarOpened ? "nav-ul-items-opened " : ""
                  } no-search`}
                >
                  {menuItems.map((item) => (
                    <li
                      key={item.label}
                      className={`nav-item ${item.isActive ? "active" : ""}`}
                      onClick={() => {
                        setIsSidebarOpened(false);
                        item.path ? navigate(item.path) : logout();
                      }}
                    >
                      <div className="nav-item-details">
                        {typeof item.icon === "string" ? (
                          <div className="nav-item-img-icon">
                            <img src={item.icon} alt="" />
                          </div>
                        ) : (
                          item.icon
                        )}
                        <span>{item.label}</span>
                      </div>
                      <i className="fa-solid fa-angle-right"></i>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          )}
        </div>
        {/* </div> */}
      </div>
    </header>
  );
};

export default Navbar;
