export const regexForText = (e: any) => {
  const re = /^[A-Za-z\s]+$/;
  if (e.target.value === "" || re.test(e.target.value)) return true;

  return false;
};

export const regexForNums = (e: any) => {
  const re = /^[0-9\b\s]+$/;
  if (e.target.value === "" || re.test(e.target.value)) return true;

  return false;
};

export const validateEmail = (email: string) => {
  const regexForEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (regexForEmail.test(email)) {
    return true;
  }

  return false;
};
