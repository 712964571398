import React from "react";

import "./App.scss";

import { useRoutes } from "react-router-dom";

import routeConfig from "./routes";

const App: React.FC = () => {
  const routes = useRoutes(routeConfig);

  return <div className="App">{routes}</div>;
};

export default App;
