import React from "react";

import CoinImg from "../../assets/coin.png";

const Coin: React.FC<ICoinProps> = ({
  size = 10,
  style = {},
  className,
  onClick = () => {},
}) => {
  return (
    <img
      src={CoinImg}
      className={className}
      style={{
        width: size,
        height: size,
        position: "relative",
        marginInline: "2px 6px",
        ...style,
      }}
      alt=""
      onClick={onClick}
    />
  );
};

export default React.memo(Coin);
