import "./FullScreenLoader.scss";

const FullScreenLoader: React.FC = () => {
  return (
    <div className="full-page-loader">
      <div className="loader__wrapper" id="loader__wrapper">
        <svg
          width="58"
          height="56"
          viewBox="0 0 58 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.9703 16.6217V48.0437H26.2334L26.7294 21.8491L20.9703 16.6217Z"
            stroke="white"
            className="loader_1"
            strokeWidth={0.5}
          />
          <path
            d="M12.9955 22.1671C12.9955 22.1671 9.57203 23.2629 6.83373 22.9462C4.18153 22.6379 0.408792 21.7613 0.012236 18.1499C-0.363704 14.7698 7.89728 1.83447 28.3775 0.195581C48.8578 -1.44331 56.972 7.63743 57.7251 11.7774C58.5085 16.0812 57.8755 19.2783 52.3989 25.2426C47.5335 30.541 51.9441 24.3611 51.9441 24.3611C51.9441 24.3611 58.3023 16.2859 55.8248 11.0802C54.3574 7.99628 46.7004 1.89709 29.8158 2.14395C10.1274 2.43054 0.606464 15.3984 1.6118 18.7665C1.91134 21.0316 5.12866 22.3008 8.07312 22.2394C11.0176 22.1779 12.9652 21.5939 12.9652 21.5939L12.9955 22.1671Z"
            stroke="white"
            className="loader_2"
            strokeWidth={0.5}
          />
          <path
            d="M44.3659 31.5404L43.1071 30.5565C43.1071 30.5565 34.0251 29.6137 26.7173 31.5404C19.4095 33.4671 11.6082 36.9676 13.899 40.8463C16.1898 44.725 25.6053 45.3102 30.2475 45.704C35.0777 46.1146 42.2461 47.2863 42.981 47.4825C44.1428 47.7944 51.2977 49.4755 53.9657 53.9466C56.2699 57.812 53.3472 51.4865 53.3472 51.4865C53.3472 51.4865 48.9245 47.4536 41.5609 46.1579C34.7418 44.9538 22.3637 44.0253 19.8449 43.2474C17.6766 42.5767 14.8291 41.0859 14.8958 39.3375C14.9577 37.7383 19.4326 34.3919 27.1527 32.5471C34.8049 30.7155 44.3659 31.5404 44.3659 31.5404Z"
            stroke="white"
            className="loader_3"
            strokeWidth={0.5}
          />
        </svg>
      </div>
    </div>
  );
};

export default FullScreenLoader;
