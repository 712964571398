import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";
import moment from "moment";

import EventImg from "../../../assets/espektro-poster.jpeg";

import Coin from "../../../components/Coin/Coin";

import { validateEventPrice } from "../../../utils/baseUtils";

const EventCard: React.FC<any> = ({ event, state, user }) => {
  const navigate = useNavigate();

  // const token = localStorage.getItem("token");

  const eventPriceVal = useMemo(() => {
    const price = validateEventPrice(user, event);

    if (!Number(price)) return <span className="text bold">FREE</span>;

    return (
      <div className="coin-details text">
        <span>{price}</span>
        <Coin />
        <span> coins</span>
      </div>
    );
  }, [event, user]);

  return (
    <div className="event-card">
      <div
        className="image"
        style={{
          backgroundImage:
            event.eventImages?.length > 0
              ? `url(${event.eventImages?.[0].url})`
              : `url(${EventImg})`,
        }}
      >
        <img
          src={
            event.eventImages?.length > 0
              ? event.eventImages?.[0].url
              : EventImg
          }
          alt=""
        />
      </div>
      <div className="details">
        <span
          className="left-border-element"
          style={{
            backgroundColor:
              state === "Past"
                ? "#ff7272"
                : state === "Ongoing"
                ? "#4a7fd4"
                : state === "Upcoming"
                ? "#af70ff"
                : "#fff",
          }}
        ></span>
        <div className="event-title-tagline">
          <h3 className="text-16">{event.title}</h3>
          <span className="title-tagline title-tagline-small">
            {event.tagLine}
          </span>
        </div>
        <div className="timeline">
          <div className="text-details-row">
            <div className="text-details">
              <span className="label">Type:</span>
              <span className="text">{event.eventType}</span>
            </div>
            <div className="text-details">
              <span className="label">Price:</span>
              {eventPriceVal}
            </div>
          </div>
          <div className="text-details">
            <span className="label">Organiser Club:</span>
            <div className="text image-detail">
              {" "}
              <img src={event.eventOrganiserClub?.image} alt="" />
              {event.eventOrganiserClub?.name}
            </div>
          </div>
          <div className="text-details-row">
            <div className="text-details">
              <span className="label">Start Date:</span>
              <span className="text">
                {moment(new Date(event.startTime))
                  .subtract("330", "minutes")
                  .format("ll")}
              </span>
            </div>
            <div className="text-details">
              <span className="label">Time:</span>
              <span className="text">
                {moment(new Date(event.startTime))
                  .subtract("330", "minutes")
                  .format("LT")}
              </span>
            </div>
          </div>
          <div className="text-details-row">
            <div className="text-details">
              <span className="label">End Date:</span>
              <span className="text">
                {moment(new Date(event.endTime))
                  .subtract("330", "minutes")
                  .format("ll")}
              </span>
            </div>
            <div className="text-details">
              <span className="label">Time:</span>
              <span className="text">
                {moment(new Date(event.endTime))
                  .subtract("330", "minutes")
                  .format("LT")}
              </span>
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary"
          onClick={() =>
            navigate(event._id, {
              state: {
                eventTimeType: state,
                user: user,
              },
            })
          }
        >
          View Event
        </button>
      </div>
    </div>
  );
};

export default EventCard;
