/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import EspektroThemeLogo from "../../../assets/espektro-theme-logo-white.png";

import { UserSignUpContext } from "../RegisterPage";

const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const { form, state } = useContext(UserSignUpContext);
  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get("eventId");

  const [detailsForm, setDetailsForm] = form;
  const [currentFormState, setCurrentFormState] = state;
  //   // console.log(detailsForm, currentFormState);

  const redirectionUrl = (path: string = "/") => {
    if (eventId) {
      return `/events/${eventId}`;
    }

    return path;
  };

  return (
    <div className="create-account-box">
      <div className="image-box">
        <img src={EspektroThemeLogo} alt="" />
      </div>
      <div className="detail-box">
        <div className="details-title">
          <h3 className="title-26">Welcome</h3>
          <p className="text-16 text-16-para">
            Welcome to Espektro 2024. Espektro is the annual techno cum
            management fest of Kalyani Government Engineering College. Please
            create your account to book your tickets and register for events.
            <br />
          </p>
        </div>
        <div className="details-action">
          <button
            className="btn btn-primary"
            onClick={() => setCurrentFormState("PersonalInfo")}
          >
            Create Account
          </button>
          <div className="bottom-text">
            Already have an account?{" "}
            <span
              className="text-link"
              onClick={() => navigate(redirectionUrl("/login"))}
            >
              Sign in
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
