import React, { useState } from "react";

import API from "../../api/api";

import "./RefreshUser.scss";
import { showAppMessage } from "../../utils/baseUtils";

const RefreshUser: React.FC<any> = ({ setUser }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const { data } = await API.getUserDetails();
      localStorage.setItem("user", JSON.stringify(data.user));
      setUser(data.user);
    } catch (error: any) {
      showAppMessage(error, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <span
      className={`${loading ? "refresh-icon loading" : "refresh-icon"}`}
      onClick={() => fetchUserProfile()}
    >
      <i className="fa-solid fa-arrows-rotate"></i>
    </span>
  );
};

export default RefreshUser;
