import React, { createContext, useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import API from "../../api/api";
import { AUTH_ROUTES } from "../../utils/constants";
import { scrollPageToTop } from "../../utils/baseUtils";

import "./Layout.scss";

export const LayoutContext = createContext<any>({} as any);

const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [layoutLoading, setLayoutLoading] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    API.updateToken(token);

    if (token === null) {
      if (
        !location.pathname.includes(
          "/login" || "/register" || "/events" || "/reset-password"
        )
      ) {
        if (
          location.pathname.includes("/events") ||
          location.pathname === "/" ||
          location.pathname === "/register"
        ) {
          return;
        } else {
          navigate("/login");
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("hello");
    scrollPageToTop();
  }, [location.pathname]);

  return (
    <LayoutContext.Provider
      value={{ pageLoading: [layoutLoading, setLayoutLoading] }}
    >
      <div className="layout-container">
        {AUTH_ROUTES.includes(location.pathname) ? null : <Navbar />}

        <div
          className="contents"
          style={{
            marginTop: AUTH_ROUTES.includes(location.pathname)
              ? "0px"
              : "100px",
          }}
        >
          <Outlet />

          {AUTH_ROUTES.includes(location.pathname) ? null : <Footer />}

          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              duration: 3000,
            }}
          />
        </div>
      </div>
      {/* )} */}
    </LayoutContext.Provider>
  );
};

export default Layout;
