import React, { useState, useEffect, useCallback } from "react";

import Ticket from "../../../../components/Ticket/Ticket";

import Loader from "../../../../components/Loader/Loader";
import Coin from "../../../../components/Coin/Coin";

import API from "../../../../api/api";
import {
  getUserFromlocalStorage,
  showAppMessage,
  validateEventPrice,
} from "../../../../utils/baseUtils";

import "./UserTickets.scss";

const UserTickets: React.FC<any> = () => {
  const [tickets, setTickets] = useState<any>([]);
  // const [event, setTickets] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const token = localStorage.getItem("token");
  // const user = JSON.parse(localStorage.getItem("user") || "");
  const [user, setUser] = useState<any>({});

  const fetchAllTickets = async () => {
    setLoading(true);
    try {
      const { data } = await API.getAllTickets();
      setTickets(data.tickets);
    } catch (error: any) {
      showAppMessage(error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      const userData = getUserFromlocalStorage();
      if (userData) {
        setUser(userData);
      }
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchAllTickets();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventPriceVal = useCallback(
    (event: any) => {
      const price = validateEventPrice(user, event);

      if (!Number(price)) return <span className="text bold">FREE</span>;

      return (
        <div className="coin-detail">
          <span>{price}</span>
          <Coin size={24} style={{ margin: 0 }} />
        </div>
      );
    },
    [user]
  );

  return (
    <div className="container user-tickets-container">
      <div className="wrapper user-tickets-wrapper">
        <h3 className="title-20">My Tickets</h3>
        {loading ? (
          <Loader />
        ) : tickets.length !== 0 ? (
          <div className="tickets-box">
            {tickets.map((ticket: any) => (
              <Ticket
                key={ticket._id}
                ticket={ticket}
                event={ticket.event[0]}
                user={user}
                eventPrice={eventPriceVal(ticket.event[0])}
              />
            ))}
          </div>
        ) : (
          <h4 style={{ textAlign: "center", fontWeight: 500 }}>
            No tickets events found
          </h4>
        )}
      </div>
    </div>
  );
};

export default UserTickets;
