import toast from "react-hot-toast";

import { KGEC_NAMES } from "./constants";

/** Converts the string into title case i.e. only first letter will be capitalized. */
declare global {
  interface String {
    toTitleCase(): string;
  }
}
// eslint-disable-next-line no-extend-native, func-names
String.prototype.toTitleCase = function () {
  return this.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const validateImgSize = (file: File) => {
  if (file.size < 5120000) {
    return true;
  } else {
    return false;
  }
};

export const validateEventPrice = (user: any, event: any): number => {
  if (!user) {
    return Number(event?.eventPrice ?? 0);
  }

  let toPayForEvent = 0;

  if (KGEC_NAMES.includes(user?.college?.toLowerCase().trim())) {
    toPayForEvent = event?.eventPriceForKGEC ?? 0;
  } else {
    toPayForEvent = event?.eventPrice;
  }

  return Number(toPayForEvent);
};

export const scrollPageToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * show app message
 * @param messageData message data from which message will be shown
 * @param type type of message to be shown
 */
export const showAppMessage = (
  messageData: any,
  type?: "success" | "error" | "warning"
) => {
  const getMessage = () => {
    if (typeof messageData === "string") {
      return messageData;
    }

    const status = messageData?.response?.status || 0;
    return status >= 400
      ? messageData?.response?.data?.message || "something went wrong"
      : messageData?.message || "request successfully completed";
  };

  const appMessage = getMessage();

  switch (type) {
    case "success":
      toast.success(appMessage);
      break;
    case "error":
      toast.error(appMessage);
      break;
    case "warning":
      toast.error(appMessage, {
        icon: (
          <div style={{ marginTop: 3 }}>
            <i
              className="fa-solid fa-triangle-exclamation"
              style={{ fontSize: 20, color: "#ffcc00" }}
            ></i>
          </div>
        ),
      });
      break;
    default:
      toast(appMessage);
      break;
  }
};

export const getUserFromlocalStorage = () => {
  let token = localStorage.getItem("token");

  if (token) {
    try {
      return JSON.parse(localStorage.getItem("user") || "");
    } catch (error) {
      return null;
    }
  }
};
